<!-- =========================================================================================
    File Name :  Payment.vue
    Description: Payment Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
    <div class="h-screen flex w-full bg-img">
        <div class="vx-col w-4/5 sm:w-4/5 md:w-3/5 lg:w-1/2 xl:w-3/5 mx-auto self-center">
            <vx-card>
                <div slot="no-body" class="full-page-bg-color">
                    <div class="vx-row">
                           <div class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center d-theme-dark-bg">
                            <div class="flex p-8 flex-col " >
                                <div class="vx-card__title mb-8 justify-content-center">
                                  <div  v-if="backendData.payment_status">
                                    <h4 class="mb-4 text-primary ">{{message}} </h4>
                                      <p>
                                       شماره مرجع :  {{backendData.refNumber}}
                                      </p>
                                      <p>
                                      شماره پیگیری :  {{backendData.trackingcode}}
                                      </p>
                                  </div>

                                  <div v-else>
                                    <h4 class="mb-4 text-danger  ">{{message}} </h4>

                                    <p></p>
                                  </div>
                                </div>
                                <vs-button class="lg:hidden" @click="backToConsole()" >بازگشت به صفحه اصلی</vs-button>
                            </div>
                        </div>
                        <div class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center">
                            <vs-button @click="backToConsole()" >بازگشت به صفحه اصلی</vs-button>
                        </div>

                    </div>
                </div>
            </vx-card>
        </div>
    </div>
</template>

<script>
export default {
  data () {
    return {
      inovice: '',
      backendData : {},
      message : ''

    }
  },
  methods : {
    getToken () {
      const a = localStorage.getItem('accessToken')
      this.$store.commit('auth/SET_BEARER', a)
    },
    getTrackingCode () {
      this.inovice = this.$route.query.trackingcode

      this.checkWithBackend()
    },
    checkWithBackend () {
      this.$http.post('/invoice/payment/status', {invoice : this.inovice}).then(res => {
        const d = res.data.params
        this.message = res.data.message
        const parsedobj = JSON.stringify(d)
        const p = JSON.parse(parsedobj)
        this.backendData = p
      }).catch((err) => {
        this.message = err.response.message
      })
    },
    backToConsole () {
      this.$router.push('/mainpage')
    }

  },
  async created () {
    await this.getToken()
    await this.getTrackingCode()

  }

}
</script>
